import React from "react";
import Select from "../../../common/select.jsx";
import { getSeriesForProviders } from "../../../requests/api-requests.js";

const defaultOption = {
  label: "All",
  value: "",
};

function SeriesFilter({ providerGuids, selectedSeries, onChange, channelId }) {
  const [isLoading, setLoading] = React.useState(false);
  const [series, setSeries] = React.useState([]);
  const lastRequestRef = React.useRef(0);

  React.useEffect(() => {
    // only run effect if something has actually changed
    setLoading(true);
    lastRequestRef.current += 1;
    const currentRequest = lastRequestRef.current;

    getSeriesForProviders(providerGuids, channelId, true)
      .then((response) => {
        if (currentRequest !== lastRequestRef.current) {
          return;
        }

        let nextSeries = response.data
          .sort((a, b) => (a.series_name > b.series_name ? 1 : -1))
          // .sort((a, b) => (a.series_active > b.series_active ? -1 : 1))
          .map((s) => ({
            label: !s.series_active ? s.series_name + " (Inactive)" : s.series_name,
            value: s.series_guid,
          }));

        if (nextSeries.length) {
          nextSeries.unshift(defaultOption);
        }

        setSeries(nextSeries);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => setLoading(false));
  }, [providerGuids, channelId]);

  React.useEffect(() => {
    if (selectedSeries && !selectedSeries.label) {
      let label = series.find((s) => s.value === selectedSeries.value)?.label;
      if (label) {
        const fixed = {
          label,
          value: selectedSeries.value,
        };
        onChange(fixed);
      }
    }
  }, [selectedSeries, series, onChange]);

  const selected = !series.length ? null : selectedSeries.value ? selectedSeries : defaultOption;

  return (
    <Select
      name={"series"}
      value={selected}
      placeholder={"No Series"}
      options={series}
      onChange={(nextSeries) => onChange(nextSeries)}
      isSearchable={true}
      isLoading={isLoading}
      isDisabled={!series.length}
      containerClass="react-select-container--light react-select-container--small"
    />
  );
}

export default SeriesFilter;
