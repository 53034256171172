import React from "react";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import axios from "../requests/axios.js";

export default function SchedulerQualityControl() {
  const [colDefs, setColDefs] = React.useState();
  const gridRef = React.useRef();

  // On load, fetch all data
  React.useEffect(() => {
    async function populateData() {
      axios
        .get(`/api/channels/quality-control`)
        .then((response) => {
          gridRef.current.api.applyTransaction({
            add: response.data,
          });
        })
        .catch((e) => {
          toast.error("Unable to reach API");
          console.error(e);
        });
    }

    populateData().then().catch();
  }, []);

  React.useEffect(() => {
    setColDefs([
      {
        field: "channel_name",
        headerName: "Channel Name",
        width: 180,
      },
      {
        field: "plan_date",
        headerName: "Plan Date",
        width: 120,
      },
      {
        field: "severity",
        headerName: "Severity",
        width: 120,
      },
      {
        field: "problem",
        headerName: "Problem",
        flex: 1,
      },
      {
        field: "program_name",
        headerName: "Program Name",
        width: 200,
      },
      {
        field: "program_start",
        headerName: "Program Start",
        width: 180,
      },
      {
        field: "program_end",
        headerName: "Program End",
        width: 180,
      },
    ]);
  }, []);

  const defaultColDef = React.useMemo(() => {
    return {
      filter: true,
      editable: false,
    };
  }, []);

  function openPlan(event) {
    window
      .open(
        `${window.location.protocol}//${window.location.host}/scheduler/${event.data.channel_id}/${event.data.plan_date}?program=${event.data.program_id}`,
        "_blank",
      )
      .focus();
  }

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            title: "Scheduler Quality Control",
            link: "/scheduler-quality-control",
          },
        ]}
      />
      <AppBody>
        <div className="ag-theme-material gstv-ag-grid">
          <AgGridReact
            rowData={undefined}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            pagination={true}
            rowSelection="multiple"
            onRowClicked={openPlan}
            ref={gridRef}
          />
        </div>
      </AppBody>
    </React.Fragment>
  );
}
