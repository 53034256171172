import React from "react";
import { AppHeading } from "../layout/parts/app-heading";
import AppBody from "../layout/parts/app-body";
import useApiRequest from "../hooks/use-api-request";
import { useParams } from "react-router-dom";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import Loader from "../common/loader";
import axios from "../requests/axios";
import RemoveTemplateDayDialog from "./templates/dialogs/remove-template-day-dialog";
import { v4 as uuid } from "uuid";
import TemplateContentList from "./templates/template-content-list/template-content-list";
import { DragDropContext } from "react-beautiful-dnd";
import TemplateDay from "./templates/template-day";
import { toast } from "react-toastify";
import SchedulerProvider from "../providers/scheduler-context";
import {
  addDays,
  addHours,
  addMinutes,
  compareAsc,
  differenceInSeconds,
  setHours,
  setMinutes,
  setSeconds,
} from "date-fns";
import dateIsBetween from "../common/date-is-between";
import EpgDndHelper from "./epg-editor/utils/dnd-helpers";
import useContentList from "../components/content-list/use-content-list";
import {
  filtersToDefinitions,
  fromApiToTemplateItem,
  fromBlockDataToTemplateItem,
  fromContentListToTemplateItem,
} from "./templates/helpers/to-template-item";
import PublishTemplateDialog from "./templates/dialogs/publish-template-dialog";
import DraggableContentBox from "../components/draggable-content-box.jsx";
import { PublishRounded } from "@mui/icons-material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import AddBlockDialog from "./templates/dialogs/add-block-dialog.jsx";
import EditBlockDialog from "./templates/dialogs/edit-block-dialog.jsx";
import CopyTemplateDayDialog from "./templates/dialogs/copy-template-day-dialog.jsx";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";

const TEMPLATE_COLUMN_WIDTH = 600;
const INSERTION_THRESHOLD = 15 * 60; // in seconds

const unixZero = new Date(0);
const REFERENCE_DATE = addMinutes(unixZero, unixZero.getTimezoneOffset() * (unixZero.getTimezoneOffset() < 0 ? 1 : -1));

export default function TemplatePage() {
  const { template_id, channelGuid } = useParams();

  const [isSaving, setSaving] = React.useState(false);
  const [publishTemplateDialog, setPublishDialog] = React.useState({
    isOpen: false,
  });
  const [removeDayDialog, setRemoveDialog] = React.useState({
    isOpen: false,
  });
  const [copyDayDialog, setCopyDialog] = React.useState({
    isOpen: false,
  });
  const [addBlockDialog, setAddBlockDialog] = React.useState({
    isOpen: false,
  });
  const [editBlockDialog, setEditBlockDialog] = React.useState({
    isOpen: false,
  });

  const [channel, setChannel] = React.useState();
  const [template, setTemplate] = React.useState();
  const [draggedItemSourceDay, setDraggedItemSourceDay] = React.useState();

  const { isLoading: templateLoading, response: templateResponse } = useApiRequest(`/api/templates/${template_id}`);
  const {
    isLoading: contentListLoading,
    programs,
    getPrograms,
  } = useContentList({
    forChannelId: template?.channel.channel_id,
  });

  // Content Box
  const [contentBoxContent, _setContentBoxContent] = React.useState(null);
  const setContentBoxContent = React.useCallback((content) => {
    _setContentBoxContent(content);
  }, []);
  const onCloseContentBox = React.useCallback(() => {
    setContentBoxContent(null);
  }, [setContentBoxContent]);

  const getDefaultChannelBreaks = React.useCallback(() => {
    const newBreaks = channel?.channel_breaks // default to channel breaks
      ? channel.channel_breaks
          .map((dayBreak) => {
            const [endHours, endMinutes, endSeconds] = dayBreak.end.split(":");
            let end = setHours(setMinutes(setSeconds(new Date(REFERENCE_DATE), endSeconds), endMinutes), endHours);

            return {
              break_time: end,
              type: "channel",
            };
          })
          .sort((a, b) => {
            return compareAsc(a.break_time, b.break_time);
          })
      : [];

    return newBreaks;
  }, [channel?.channel_breaks]);

  const openRemoveDialog = React.useCallback((dayNumber) => {
    setRemoveDialog({
      isOpen: true,
      dayNumber,
    });
  }, []);

  const closeRemoveDialog = React.useCallback(() => {
    setRemoveDialog({
      isOpen: false,
    });
  }, []);

  const openCopyDialog = React.useCallback((dayNumber) => {
    setCopyDialog({
      isOpen: true,
      dayNumber,
    });
  }, []);

  const closeCopyDialog = React.useCallback(() => {
    setCopyDialog({
      isOpen: false,
    });
  }, []);

  const copyTemplateDay = React.useCallback(
    (sourceDay, targetDay) => {
      if (template?.days.length) {
        if (targetDay >= 8) {
          toast.info("Days in template cannot exceed seven.");
          return;
        }

        setTemplate((prev) => {
          const fromIndex = template.days.findIndex((day) => day.day_number === sourceDay);
          let targetIndex = template.days.findIndex((day) => day.day_number === targetDay);
          if (targetIndex === -1) {
            targetIndex = prev.days.length;
          }

          const next = {
            ...template.days[fromIndex],
            day_number: targetIndex + 1,
            breaks: template.days[fromIndex].breaks.map((br) => {
              const resetBreak = {
                ...br,
              };
              delete resetBreak.template_day_break_id;
              delete resetBreak.template_day_id;
              return resetBreak;
            }),
            items: template.days[fromIndex].items.map((item) => {
              const resetItem = {
                ...item,
                drag_id: uuid(),
              };

              delete resetItem.template_day_item_id;
              delete resetItem.template_day_id;

              return resetItem;
            }),
          };
          delete next.template_day_id;

          return {
            ...prev,
            hasUnsavedChanges: true,
            days: [...prev.days.slice(0, targetIndex), next, ...prev.days.slice(targetIndex + 1)],
          };
        });

        closeCopyDialog();
      }
    },
    [closeCopyDialog, template?.days],
  );

  const openPublishDialog = React.useCallback(() => {
    setPublishDialog({
      isOpen: true,
      channelId: channel?.channel_id,
      templateId: template?.template_id,
      daysCount: template?.days?.length ?? 0,
      channelName: channel?.display_name,
      hasUnsavedChanges: template?.hasUnsavedChanges,
    });
  }, [
    channel?.channel_id,
    channel?.display_name,
    template?.days?.length,
    template?.hasUnsavedChanges,
    template?.template_id,
  ]);

  const closePublishDialog = React.useCallback(() => {
    setPublishDialog({
      isOpen: false,
    });
  }, []);

  const openAddBlockDialog = React.useCallback((dayNumber, segIndex, segments) => {
    setAddBlockDialog({
      isOpen: true,
      dayNumber,
      segIndex,
      segments,
    });
  }, []);

  const closeAddBlockDialog = React.useCallback(() => {
    setAddBlockDialog({
      isOpen: false,
    });
  }, []);

  const openEditBlockDialog = React.useCallback((dayNumber, segments, templateBlock) => {
    setEditBlockDialog({
      isOpen: true,
      dayNumber,
      segments,
      templateBlock,
    });
  }, []);

  const closeEditBlockDialog = React.useCallback(() => {
    setEditBlockDialog({
      isOpen: false,
    });
  }, []);

  const createTemplateBreaksFromSourceBreaks = React.useCallback((sourceBreaks, channelBreaks = []) => {
    let breaks = [];
    if (!sourceBreaks || sourceBreaks.length === 0) {
      breaks = channelBreaks.length
        ? channelBreaks
            .map((dayBreak) => {
              const [hours, minutes, seconds] = dayBreak.start.split(":");
              const [endHours, endMinutes, endSeconds] = dayBreak.end.split(":");
              let start = addHours(addMinutes(setSeconds(REFERENCE_DATE, seconds), minutes), hours);
              let end = addHours(addMinutes(setSeconds(REFERENCE_DATE, endSeconds), endMinutes), endHours);

              return {
                start,
                end,
                duration: differenceInSeconds(end, start),
                type: "channel",
              };
            })
            .sort((a, b) => {
              return compareAsc(a.start, b.start);
            })
        : [];
    } else {
      let referenceDate = new Date(REFERENCE_DATE);
      breaks = sourceBreaks
        .sort((a, b) => {
          return compareAsc(a.break_time, b.break_time);
        })
        .map((dayBreak) => {
          const start = referenceDate;
          const end = dayBreak.break_time;
          referenceDate = dayBreak.break_time;

          return {
            start,
            end,
            duration: differenceInSeconds(end, start),
            type: dayBreak.type,
          };
        });
    }

    return breaks;
  }, []);

  const addTemplateDay = React.useCallback(() => {
    if (template?.days.length >= 7) {
      toast.info("Days in template cannot exceed seven.");
      return;
    }

    setTemplate((prev) => ({
      ...prev,
      hasUnsavedChanges: true,
      days: [
        ...prev.days,
        {
          day_number: prev.days.length + 1, // adds empty day to end
          items: [],
          breaks: getDefaultChannelBreaks(),
          templateBreaks: createTemplateBreaksFromSourceBreaks(null, channel?.channel_breaks),
        },
      ],
    }));
  }, [channel?.channel_breaks, createTemplateBreaksFromSourceBreaks, getDefaultChannelBreaks, template?.days.length]);

  const removeTemplateDay = React.useCallback(
    (dayNumber) => {
      if (template) {
        const removeIndex = template.days.findIndex((day) => day.day_number === dayNumber);
        if (removeIndex < 0) {
          return;
        }

        let daysCopy = [...template.days];
        daysCopy.splice(removeIndex, 1);

        // recalculate day numbers
        daysCopy = daysCopy.map((day, index) => ({
          ...day,
          day_number: index + 1,
        }));

        setTemplate((prev) => ({
          ...prev,
          days: daysCopy,
          hasUnsavedChanges: true,
        }));

        closeRemoveDialog();
      }
    },
    [closeRemoveDialog, template],
  );

  const updateTemplateDay = React.useCallback(
    (dayNumber, data) => {
      setTemplate((prev) => {
        const dayIndex = prev.days.findIndex((day) => day.day_number === dayNumber);

        // update templateBreaks based on latest breaks
        const updatedDay = {
          ...data,
          templateBreaks: createTemplateBreaksFromSourceBreaks(data.breaks, channel?.channel_breaks),
        };

        const next = {
          ...prev,
          hasUnsavedChanges: true,
          days: [...prev.days.slice(0, dayIndex), updatedDay, ...prev.days.slice(dayIndex + 1)],
        };
        return next;
      });
    },
    [channel?.channel_breaks, createTemplateBreaksFromSourceBreaks],
  );

  const updateTemplateDayItems = React.useCallback(
    (dayNumber, updatedItem) => {
      const targetDay = template?.days.find((day) => day.day_number === dayNumber);
      if (!targetDay) {
        toast.error("Could not find the target day.");
        return;
      }
      let itemIndex = targetDay.items.findIndex((item) => item.drag_id === updatedItem.drag_id);
      if (itemIndex < 0) itemIndex = targetDay.items.length - 1; // place new items at the end, recalculate later

      const nextItems = [...targetDay.items.slice(0, itemIndex), updatedItem, ...targetDay.items.slice(itemIndex + 1)];

      updateTemplateDay(dayNumber, {
        ...targetDay,
        items: nextItems,
      });
    },
    [template?.days, updateTemplateDay],
  );

  const isDropDisabled = React.useCallback(
    (droppableId) => {
      if (!draggedItemSourceDay) {
        return false;
      }

      const templateDayNumber = droppableId.split("-")[1];
      return draggedItemSourceDay !== templateDayNumber;
    },
    [draggedItemSourceDay],
  );

  const addItem = React.useCallback(
    (item) => {
      const targetDay = template?.days.findLast((day) => {
        const referenceItem = day.items[day.items.length - 1];

        return (
          differenceInSeconds(addDays(new Date(REFERENCE_DATE), 1), new Date(referenceItem.till)) >= INSERTION_THRESHOLD
        );
      });

      if (!targetDay) {
        return;
      }

      const dndHelper = EpgDndHelper(channel?.channel_id, REFERENCE_DATE, targetDay.templateBreaks);

      let templateItem = fromContentListToTemplateItem(item);
      let list = [...targetDay.items];
      list = dndHelper.insert(list, templateItem, list.length, null);

      updateTemplateDay(targetDay.day_number, {
        ...targetDay,
        items: list,
      });
    },
    [channel?.channel_id, template?.days, updateTemplateDay],
  );

  const addTemplateBlock = React.useCallback(
    (block) => {
      const targetDay = template?.days.find((day) => day.day_number === block.dayNumber);
      if (!targetDay) {
        toast.error("Could not find the target day.");
        return;
      }

      const dndHelper = EpgDndHelper(channel?.channel_id, REFERENCE_DATE, targetDay.templateBreaks);
      const blockItem = fromBlockDataToTemplateItem(block);
      let list = [...targetDay.items];

      // find the target segment
      const availableIndex = list.findLastIndex((item) => item.since < block.segment.end);

      let earliestEnd = block.segment.end < blockItem.till ? block.segment.end : blockItem.till;
      list = dndHelper.insert(list, blockItem, availableIndex + 1, block.segment.start, earliestEnd);
      updateTemplateDay(targetDay.day_number, {
        ...targetDay,
        items: list,
      });
    },
    [channel?.channel_id, template?.days, updateTemplateDay],
  );

  React.useEffect(() => {
    if (templateResponse.data && !template && !channel) {
      const templateData = templateResponse.data;
      templateData.days?.forEach((day) => {
        /// BREAKS ///
        if (!day.breaks || !day.breaks.length) {
          day.breaks = getDefaultChannelBreaks();
        }

        day.breaks.forEach((dBreak) => {
          dBreak.break_time = fromUtcDate(dBreak.break_time);
        });

        /// ITEMS ///
        day.items = day.items.map((item) => {
          return fromApiToTemplateItem(item);
        });

        /// TEMPLATE_BREAKS ///
        day.templateBreaks = createTemplateBreaksFromSourceBreaks(day.breaks, templateData.channel.channel_breaks);
      });

      templateData.hasUnsavedChanges = false;

      setTemplate(templateData);
      setChannel(templateData.channel);
    }
  }, [templateResponse, getDefaultChannelBreaks, template, channel, createTemplateBreaksFromSourceBreaks]);

  const breadcrumbs = React.useMemo(() => {
    if (!channelGuid) {
      return [
        {
          link: "/templates",
          title: "Templates",
        },
        {
          link: `/templates/${template_id}`,
          title: !template || !channel ? "Loading..." : `${template?.template_name} (${channel?.display_name})`,
        },
      ];
    }

    return [
      {
        link: "/scheduler",
        title: "Scheduler",
      },
      {
        link: `/scheduler/${channelGuid}/templates`,
        title: channelGuid && !!channel ? `${channel.display_name} Templates` : "Templates",
      },
      {
        link: `/scheduler/${channelGuid}/templates/${template_id}`,
        title: !template ? "Loading..." : template?.template_name,
      },
    ];
  }, [channelGuid, template, channel, template_id]);
  // dates are visually in the users timezone, but in reality they are UTC
  // this resolves the call that axios will make to do .toISOString() to keep things UTC-like
  function fromUtcDate(utcDate) {
    let offsetDate = new Date(utcDate);
    const modifier = offsetDate < 0 ? -1 : 1;
    offsetDate = addMinutes(offsetDate, offsetDate.getTimezoneOffset() * modifier);
    return offsetDate;
  }

  const saveTemplate = React.useCallback(() => {
    setSaving(true);

    const payload = { ...template };
    const days = payload.days.map((day) => {
      const breaks = day.breaks.map((dBreak) => ({
        ...dBreak,
        break_time: dBreak.break_time.toLocaleString("en-US"), // MM-dd-YYYY etc
      }));

      // @TODO add seed info
      const items = day.items.map((item) => {
        const apiItem = {
          link_type: item.type,
          link_guid: item.id,
          duration: differenceInSeconds(item.till, item.since), // duration on scheduler
          starts_at: item.since.toLocaleString("en-US"),
          ends_at: item.till.toLocaleString("en-US"),
        };

        if (day.template_day_id) {
          apiItem.template_day_id = day.template_day_id;
        }

        if (item.template_day_item_id) {
          apiItem.template_day_item_id = item.template_day_item_id;
        }

        //  @TODO refactor this with better pattern
        if (item.type === "template_block") {
          apiItem.link = {
            block_label: item.title,
            block_type: item.blockType, // expects filter | segment
          };

          if (item.blockType === "copy") {
            apiItem.link.block_type = "segment";

            apiItem.link.definitions = [
              {
                definition_type: "segment",
                definition_value: item.copyFrom.toLocaleString("en-US"),
              },
            ];
          }

          if (item.blockType === "filter") {
            apiItem.link.definitions = filtersToDefinitions(item.filters);
          }

          if (template.template_id) {
            apiItem.link.template_id = template.template_id;
          }

          if (item.template_block_id) {
            apiItem.link.template_block_id = item.template_block_id;
            apiItem.link_guid = item.template_block_id;
          } else {
            apiItem.link_guid = ""; // new blocks have a randomly generated guid and backend expects no guid for new blocks
          }
        }

        return apiItem;
      });

      return {
        ...day,
        breaks,
        items,
      };
    });

    payload.days = days;

    axios
      .post(`api/templates/${template_id}`, payload)
      .then(() => {
        toast.success("Template saved successfully!");
        setTemplate((prev) => ({
          ...prev,
          hasUnsavedChanges: false,
        }));
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => setSaving(false));
  }, [template, template_id]);

  function getSegmentIndexWithinTime(breaksArray, time) {
    return breaksArray.findIndex((s) => dateIsBetween(time, s.start, s.end, "[)"));
  }

  function onDragStart({ draggableId, source }) {
    if (draggableId.includes("template-content-list")) {
      return;
    }

    requestAnimationFrame(() => {
      setDraggedItemSourceDay(source.droppableId.split("-")[1]);
    });
  }

  function onDragEnd({ source, destination }) {
    setDraggedItemSourceDay(null);

    if (!destination || destination.droppableId === "template-content-list") {
      return;
    }

    const targetDay = template.days.find((day) => day.day_number == destination.droppableId.split("-")[1]);
    if (!targetDay) {
      return;
    }

    let currentBreakStartTime = null;
    let currentBreakEndTime = null;
    let sourceSegment = null;
    // destination id might include a break
    if (destination.droppableId.includes(":")) {
      const breakTime = destination.droppableId.split("-").slice(-1)[0];
      const [hours, minutes, seconds] = breakTime.split(":");
      currentBreakStartTime = setHours(setMinutes(setSeconds(REFERENCE_DATE, seconds), minutes), hours);
    }

    // source might also include a break
    if (source.droppableId.includes(":")) {
      const breakTime = source.droppableId.split("-").slice(-1)[0];
      const [hours, minutes, seconds] = breakTime.split(":");
      sourceSegment = setHours(setMinutes(setSeconds(REFERENCE_DATE, seconds), minutes), hours);
    }

    let isInsert = source.droppableId === "template-content-list";
    let list = [...targetDay.items];
    const dndHelper = EpgDndHelper(channel.channel_id, REFERENCE_DATE, targetDay.templateBreaks);

    let absoluteDestinationIndex = destination.index;
    let absoluteSourceIndex = source.index;

    if (currentBreakStartTime) {
      // indices are relative to the segment they are in, we need the absolute index from here on
      const indexedCounter = [];
      for (let i = 0; i < list.length; i++) {
        const currIndex = getSegmentIndexWithinTime(targetDay.templateBreaks, list[i].since);
        if (indexedCounter[currIndex]) {
          indexedCounter[currIndex] += 1;
        } else {
          indexedCounter[currIndex] = 1;
        }
      }
      const toSegmentIndex = getSegmentIndexWithinTime(targetDay.templateBreaks, currentBreakStartTime);
      const fromSegmentIndex = sourceSegment
        ? getSegmentIndexWithinTime(targetDay.templateBreaks, sourceSegment)
        : null;
      absoluteDestinationIndex += indexedCounter.slice(0, toSegmentIndex).reduce((prev, curr) => prev + curr, 0);
      if (fromSegmentIndex !== null && fromSegmentIndex < toSegmentIndex) {
        // we have for sure already counted this element
        absoluteDestinationIndex--;
      }

      currentBreakEndTime = targetDay.templateBreaks[toSegmentIndex].end;

      if (!isInsert) {
        const fromSegmentIndex = getSegmentIndexWithinTime(targetDay.templateBreaks, sourceSegment);
        absoluteSourceIndex += indexedCounter.slice(0, fromSegmentIndex).reduce((prev, curr) => prev + curr, 0);
      }
    }

    let item = {};

    if (isInsert) {
      item = fromContentListToTemplateItem(programs.data[absoluteSourceIndex]);
      list = dndHelper.insert(list, item, absoluteDestinationIndex, currentBreakStartTime, currentBreakEndTime);
    } else {
      list = dndHelper.move(
        list,
        absoluteSourceIndex,
        absoluteDestinationIndex,
        currentBreakStartTime,
        currentBreakEndTime,
      );
    }

    updateTemplateDay(targetDay.day_number, {
      ...targetDay,
      items: list,
    });
  }

  const onSetTemplateDefault = React.useCallback(() => {
    axios
      .post(`api/channels/${channel?.channel_id}/default-template`, { template_id: template.template_id })
      .then((resp) => {
        toast.success("Template set as channel default");
        setChannel((prev) => ({
          ...prev,
          default_template: resp.data.default_template,
        }));
      })
      .catch((e) => {
        toast.error("Error: Could not set this template as the default");
        console.error(e);
      });
  }, [channel?.channel_id, template?.template_id]);

  const onUnsetTemplateDefault = React.useCallback(() => {
    axios
      .post(`api/channels/${channel?.channel_id}/default-template`, { template_id: null })
      .then((resp) => {
        toast.success("Template removed as channel default");
        setChannel((prev) => ({
          ...prev,
          default_template: resp.data.default_template,
        }));
      })
      .catch((e) => {
        toast.error("Error: Could not unset this template as the default");
        console.error(e);
      });
  }, [channel?.channel_id]);

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={breadcrumbs}
        rightActions={[
          {
            when: !templateLoading && !isSaving && !!channel?.default_template,
            icon: StarRoundedIcon,
            balloonLabel: "Unset as default template",
            onClick: onUnsetTemplateDefault,
          },
          {
            when: !templateLoading && !isSaving && !channel?.default_template,
            icon: StarBorderRoundedIcon,
            balloonLabel: "Set as default template",
            onClick: onSetTemplateDefault,
          },
          {
            when: !isSaving,
            icon: PublishRounded,
            balloonLabel: "Schedule Template",
            onClick: openPublishDialog,
          },
          {
            when: !isSaving,
            icon: SaveRoundedIcon,
            balloonLabel: "Save",
            onClick: saveTemplate,
          },
          {
            when: isSaving,
            icon: Loader,
          },
        ]}
      />
      <SchedulerProvider timezone="UTC">
        <AppBody loading={templateLoading}>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <div className="template-page">
              <div className="template-page__content-list">
                {!template ? (
                  <Loader />
                ) : (
                  <TemplateContentList
                    template={template}
                    programs={programs}
                    getPrograms={getPrograms}
                    isLoading={contentListLoading}
                    addItem={addItem}
                  />
                )}
              </div>
              <div className="template-page__template-days">
                <div
                  className="template-page__template-days__inner"
                  style={template?.days ? { width: `${(template.days.length + 1) * TEMPLATE_COLUMN_WIDTH}px` } : {}}
                >
                  {template?.days
                    ? template.days.map((day, index) => (
                        <TemplateDay
                          day={day}
                          refDate={REFERENCE_DATE}
                          channelBreaks={channel?.channel_breaks ?? []}
                          templateBreaks={day.templateBreaks}
                          channelId={channel?.channel_id}
                          index={index}
                          removeTemplateDay={openRemoveDialog}
                          copyTemplateDay={openCopyDialog}
                          updateTemplateDay={updateTemplateDay}
                          isDropDisabled={isDropDisabled}
                          templateColumnWidth={TEMPLATE_COLUMN_WIDTH}
                          key={index}
                          setActiveContent={setContentBoxContent}
                          setActiveBlock={openEditBlockDialog}
                          insertionThreshold={INSERTION_THRESHOLD}
                          addBlock={openAddBlockDialog}
                        />
                      ))
                    : null}
                  {template?.days.length < 7 ? (
                    <div className="template-page__add-day">
                      <button className="btn btn--icon" onClick={addTemplateDay}>
                        <AddCircleRoundedIcon />
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </DragDropContext>
        </AppBody>
      </SchedulerProvider>
      <RemoveTemplateDayDialog
        isOpen={removeDayDialog.isOpen}
        onClose={closeRemoveDialog}
        onSubmit={removeTemplateDay}
        dayNumber={removeDayDialog.dayNumber}
        key={`remove_day_dialog-${uuid()}`}
      />
      <CopyTemplateDayDialog
        isOpen={copyDayDialog.isOpen}
        onClose={closeCopyDialog}
        onSubmit={copyTemplateDay}
        dayNumber={copyDayDialog.dayNumber}
        key={`copy_day_dialog-${uuid()}`}
        dayOptions={template?.days.map((d) => d.day_number) ?? []}
      />
      <PublishTemplateDialog
        isOpen={publishTemplateDialog.isOpen}
        onClose={closePublishDialog}
        channelId={publishTemplateDialog.channelId}
        templateId={publishTemplateDialog.templateId}
        daysCount={publishTemplateDialog.daysCount}
        channelName={publishTemplateDialog.channelName}
        hasUnsavedChanges={publishTemplateDialog.hasUnsavedChanges}
        key={`publish_template_dialog-${uuid()}`}
      />
      <AddBlockDialog
        isOpen={addBlockDialog.isOpen}
        onClose={closeAddBlockDialog}
        dayNumber={addBlockDialog.dayNumber}
        segIndex={addBlockDialog.segIndex}
        providers={channel?.providers}
        onSubmit={addTemplateBlock}
        segments={addBlockDialog.segments}
        key={`add_template_block_dialog-${uuid()}`}
        channelId={channel?.channel_id}
      />
      <EditBlockDialog
        isOpen={editBlockDialog.isOpen}
        onClose={closeEditBlockDialog}
        dayNumber={editBlockDialog.dayNumber}
        segments={editBlockDialog.segments}
        providers={channel?.providers}
        onSubmit={updateTemplateDayItems}
        templateBlock={editBlockDialog.templateBlock}
        key={`edit_template_block_dialog-${uuid()}`}
      />

      <DraggableContentBox
        visible={contentBoxContent !== null}
        onClose={onCloseContentBox}
        content={contentBoxContent}
      />
    </React.Fragment>
  );
}
