import React from "react";
import { AppHeading } from "../layout/parts/app-heading";
import AppBody from "../layout/parts/app-body";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useParams } from "react-router-dom";
import useApiRequest from "../hooks/use-api-request";
import { format, parse, startOfWeek, getDay } from "date-fns";
import { enUS } from "date-fns/locale";
import CalendarEventPopup from "./channel-calendar/calendar-event-popup";
import { v4 as uuid } from "uuid";
import AddCircleRounded from "@mui/icons-material/AddCircleRounded";
import AddTemplateScheduleDialog from "./channel-calendar/add-template-schedule-dialog";
import CalendarToolbar from "./channel-calendar/calendar-toolbar";

export default function ChannelCalendarPage() {
  const { channelGuid } = useParams();
  const [currentDate, setDate] = React.useState(new Date());
  const [events, setEvents] = React.useState();
  const [channel, setChannel] = React.useState();
  const [activeMonth, setMonth] = React.useState(format(new Date(), "yyyy-MM"));
  const [calendarEventModal, setCalendarEventModal] = React.useState({
    open: false,
  });
  const [addScheduleDialog, setScheduleDialog] = React.useState({
    open: false,
  });

  const {
    response: eventsResponse,
    isLoading: eventsLoading,
    refreshRequest: refreshCalendarEvents,
    // error: eventsError,
  } = useApiRequest(`/api/channels/${channelGuid}/calendar?month=${activeMonth}`);

  // @TODO channel is only being loaded for channel.display_name. find a way to remove?
  const {
    response: channelResponse,
    isLoading: channelLoading,
    // error: eventsError,
  } = useApiRequest(`/api/channels/${channelGuid}`);

  const onDateChange = React.useCallback((newDate) => {
    setDate(newDate);
    setMonth(format(newDate, "yyyy-MM"));
  }, []);

  React.useEffect(() => {
    if (!channel && !channelLoading) {
      setChannel(channelResponse.data);
    }
  }, [channel, channelLoading, channelResponse]);

  React.useEffect(() => {
    if (!eventsLoading) {
      setEvents(
        eventsResponse.data.map((ev) => ({
          title: `${ev.label} - Day ${ev.day_number}`,
          start: new Date(ev.start),
          end: new Date(ev.end),
          template_schedule: ev.template_schedule,
          day_number: ev.day_number,
          is_priority: ev.is_priority,
        })),
      );
    }
  }, [eventsLoading, eventsResponse]);

  function openCalendarEvent(calendarEvent) {
    setCalendarEventModal({
      open: true,
      calendarEvent,
    });
  }

  function closeCalendarEvent() {
    setCalendarEventModal({
      open: false,
    });
  }

  function openAddScheduleDialog() {
    setScheduleDialog({
      open: true,
    });
  }

  function closeAddScheduleDialog() {
    setScheduleDialog({
      open: false,
    });
  }

  const locales = {
    "en-US": enUS,
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "/scheduler",
            title: "Channels",
          },
          {
            link: `/channels/${channelGuid}`,
            title: !channel ? "Loading..." : `${channel.display_name} - Calendar`,
          },
        ]}
        rightActions={[
          {
            icon: AddCircleRounded,
            onClick: openAddScheduleDialog,
            balloonLabel: "Add Template Schedule",
          },
        ]}
      ></AppHeading>
      <AppBody
        // loading={isLoading && channel !== null}
        loading={false}
      >
        <div className="gw gw--full-height">
          <div className="g g--1-of-1 g--full-height">
            <Calendar
              components={{
                toolbar: (props) => <CalendarToolbar {...props} updateDate={setDate} />,
              }}
              date={currentDate}
              localizer={localizer}
              events={events}
              onSelectEvent={openCalendarEvent}
              onNavigate={onDateChange}
              views={["month"]}
              doShowMoreDrillDown={false}
              showAllEvents={true}
              eventPropGetter={(event) => ({
                // className: event.is_priority ? "rbc-event--priority" : "",
                style: event.is_priority
                  ? {
                      backgroundColor: "#6a10FF", // $clr-primary
                    }
                  : {
                      backgroundColor: "#b488ff", // $clr-primary-lt
                    },
              })}
            />
          </div>
        </div>
      </AppBody>
      <CalendarEventPopup
        isOpen={calendarEventModal.open}
        onClose={closeCalendarEvent}
        channelGuid={channelGuid}
        calendarEvent={calendarEventModal.calendarEvent}
        activeMonth={activeMonth}
        onSuccess={refreshCalendarEvents}
        key={`calendar-event-popup-${uuid()}`}
      />
      <AddTemplateScheduleDialog
        isOpen={addScheduleDialog.open}
        onClose={closeAddScheduleDialog}
        onSuccess={refreshCalendarEvents}
        channelGuid={channelGuid}
        templates={channel?.templates ?? []}
        key={`add-template-schedule-${uuid()}`}
      />
    </React.Fragment>
  );
}
