import React from "react";
import { Link, useParams } from "react-router-dom";
import { AppHeading } from "../layout/parts/app-heading";
import AppBody from "../layout/parts/app-body";
import ContentCard from "./library/components/content-card";
import DataTable from "../components/data-table/data-table";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { v4 as uuid } from "uuid";
import axios from "../requests/axios";
import { toast } from "react-toastify";
import AddTemplateDialog from "./templates/dialogs/add-template-dialog";
import TrashTemplateDialog from "./templates/dialogs/trash-template-dialog";

export default function TemplateListPage() {
  const [channels, setChannels] = React.useState([]);
  const [channel, setChannel] = React.useState();

  const { channelGuid } = useParams();
  const [showAddDialog, setAddDialog] = React.useState(false);
  const [trashDialog, setTrashDialog] = React.useState({
    open: false,
  });
  const tableRef = React.useRef();

  const columns = React.useMemo(
    () => [
      {
        label: "Template Name",
        key: "template_name",
        value: "template_name",
        sortable: true,
        width: 45,
      },
      {
        label: "Channel Name",
        key: "channel_name",
        value: "channel.display_name",
        width: 45,
      },
      {
        label: "",
        value: "",
        key: "0",
        width: 10,
        renderValue: (row) => (
          <span className="data-table__action-cell">
            <span className="data-table__action-cell__item data-table__icon">
              <button
                className="btn btn--icon"
                onClick={openTrashDialog.bind(null, row)}
                aria-label="Delete template"
                data-balloon-pos="left"
              >
                <DeleteForeverRoundedIcon />
              </button>
            </span>
            <span className="data-table__action-cell__item data-table__icon">
              <Link className="link--base u-fs--micro" to={`${row.template_id}`}>
                <ArrowCircleRightRoundedIcon />
              </Link>
            </span>
          </span>
        ),
      },
    ],
    [],
  );

  React.useEffect(() => {
    axios
      .get(`api/channels`)
      .then((resp) => {
        setChannels(resp.data.data);
      })
      .catch((e) => {
        console.error(e);
        toast.error("Error loading channel data. Please contact support.");
      });
  }, []);

  React.useEffect(() => {
    if (channelGuid) {
      axios.get(`api/channels/${channelGuid}`).then((resp) => setChannel(resp.data));
    }
  }, [channelGuid]);

  function refreshList() {
    tableRef.current?.refreshList();
  }

  function openTrashDialog(template) {
    setTrashDialog({
      open: true,
      template: {
        id: template.template_id,
        name: template.template_name,
      },
    });
  }

  function closeTrashDialog() {
    setTrashDialog({
      open: false,
      template: null,
    });
  }

  const breadcrumbs = React.useMemo(() => {
    if (!channelGuid) {
      return [
        {
          link: "/templates",
          title: "Templates",
        },
      ];
    }

    return [
      {
        link: "/scheduler",
        title: "Scheduler",
      },
      {
        link: "",
        title: channel?.display_name ? `${channel.display_name} Templates` : "Templates",
      },
    ];
  }, [channelGuid, channel]);

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={breadcrumbs}
        rightActions={[
          {
            balloonLabel: "Add Template",
            icon: AddCircleRoundedIcon,
            onClick: () => setAddDialog(true),
          },
        ]}
      />
      <AppBody>
        <div className="gw gw--full-height">
          <div className="g g--1-of-1 g--full-height">
            <ContentCard noPadding>
              <DataTable
                url={`/api/templates${channelGuid ? `?channel_id=${channelGuid}` : ""}`}
                withSearch={true}
                columns={columns}
                ref={tableRef}
              />
            </ContentCard>
          </div>
        </div>
      </AppBody>
      <AddTemplateDialog
        isOpen={showAddDialog}
        onClose={() => setAddDialog(false)}
        channels={channels}
        key={`add_template_dialog-${uuid()}`}
        channelGuid={channelGuid}
      />
      <TrashTemplateDialog
        isOpen={trashDialog.open}
        onClose={closeTrashDialog}
        onSuccess={refreshList}
        template={trashDialog.template}
        key={`trash_dialog-${uuid()}`}
      />
    </React.Fragment>
  );
}
