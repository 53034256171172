import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { dateToHourMinutesSeconds } from "../../common/duration-formatting.js";
import { addMinutes, endOfDay, startOfDay, differenceInSeconds, subMinutes, differenceInMinutes } from "date-fns";
import dateIsBetween from "../../common/date-is-between.js";
import { toast } from "react-toastify";
import CopySegmentDialog from "../../pages/epg-editor/components/dialogs/copy-segment-dialog.jsx";
import { v4 as uuid } from "uuid";
import DraggableProgram from "./draggable-program.jsx";
import { isUtcPast } from "../../common/is-utc-past.js";
import { getTimezoneOffset } from "date-fns-tz";
import { useSchedulerContext } from "../../providers/scheduler-context.jsx";
import useSegments from "./hooks/useSegments.jsx";
import CalendarMarkers from "./calendarMarkers.jsx";
import CurrentTimeMarker from "../../pages/epg-editor/components/current-time-marker.jsx";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { getHeightStyleFromSeconds } from "./helpers/style-helpers.js";
import {
  getHeightFromSeconds,
  getSmallOffset,
  getPrependedMargin,
  getMarkerPopOutWidth,
  getTimeRemainingInSegment,
  getLastProgramForSegment,
  getProgramVerticalOffset,
  getTimeRemainingInSegmentHis,
} from "./helpers/scheduler-helpers.js";
import { getTimezoneDifference } from "../../common/clock-change-safe-time.js";
import CalendarFilterBlocks from "./calendar-filter-blocks.jsx";

const SMALL_PROGRAM_MAX_HEIGHT = 30;
const SEGMENT_OFFSET_PER_MINUTE = 48 / 10;
const SEGMENT_OFFSET_THRESHOLD_MINUTES = 10;

function VerticalScheduler({
  epg,
  planBreaks,
  droppableId,
  planDate,
  addPlanBreak,
  deletePlanBreak,
  selectProgram,
  onPlay,
  removeProgram,
  copySegment,
  updateProgramDuration,
  setCustomTimeRange,
  dropsDisabled,
  onProgramDrag,
  customRange,
  blocks,
  applyFilters,
}) {
  const [showCopyDialog, setShowCopyDialog] = React.useState(false);
  const [targetSegmentIndex, setTargetSegmentIndex] = React.useState(0);
  const [markerPopoutWidth, setMarkerPopoutWidth] = React.useState();
  const calendarDiv = React.useRef();
  const timelineContainerRef = React.useRef();
  const { timezone, toOffsetDateWithClockChangeSafety } = useSchedulerContext();
  const segments = useSegments(planDate, planBreaks, epg);
  const timezoneOffset = timezone !== "UTC" ? getTimezoneOffset("Europe/London", planDate) / 1000 : 0;
  const pixelsPerSection = 4;
  const secondsPerSection = 60; // change this when changing active availableMarkerMinuteSpread value
  const pixelsPerSecond = (1 / secondsPerSection) * pixelsPerSection;

  React.useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      setMarkerPopoutWidth(getMarkerPopOutWidth(calendarDiv));
    });

    if (calendarDiv.current) {
      observer.observe(calendarDiv.current);
    } else {
      // hack to make sure we avoid timing issues
      setTimeout(() => {
        if (calendarDiv.current) {
          observer.observe(calendarDiv.current);
        }
      }, 300);
    }

    return () => observer.disconnect();
  }, []);

  React.useLayoutEffect(() => {
    setTimeout(() => {
      if (window.location.search.includes("program")) {
        const container = timelineContainerRef.current.parentElement;
        const id = window.location.search.split("?")[1].split("&")[0].split("=")[1];
        const search = `vertical-epg-${id}`;
        const attribute = "data-rbd-draggable-id";
        const element = container.querySelector(`[${attribute}='${search}']`);
        if (element) {
          const elementTop = element.getBoundingClientRect().top - 30;
          const containerScrollTop = container.getBoundingClientRect().top;

          container.scrollTo({
            top: elementTop - containerScrollTop,
            behavior: "smooth",
          });
        }
      }
    }, 640);
  }, []);

  const changeZoomLevel = React.useCallback(
    (start, end) => {
      setCustomTimeRange([start, end]);
    },
    [setCustomTimeRange],
  );

  // early exit if we don't have all the information
  if (planBreaks.length === 0) {
    return null;
  }

  function onProgramClick(program) {
    selectProgram(program);
    onPlay(program.__gstvMeta.video_asset.asset_id);
  }

  function addBreakAtPosition(date) {
    const [segment] = segments.filter((segment) => {
      return dateIsBetween(date, segment.start, segment.end, "(]");
    });

    if (!segment) {
      toast.error("Unable to add a break there.");
      return;
    }

    // If overlapping with an item, set to either start or end of the program
    if (segment.items.length > 0) {
      let fixedDate = null;
      segment.items.forEach((item) => {
        if (item.till > date && item.since < date) {
          // see which is closer, since or till
          const sinceDiff = Math.abs(differenceInSeconds(date, item.since));
          const tillDiff = Math.abs(differenceInSeconds(date, item.till));
          // ensure we are no conflicting with segment
          fixedDate = sinceDiff > tillDiff ? new Date(item.till) : new Date(item.since);
        }
      });

      if (+segment.start === +fixedDate || +segment.end === +fixedDate) {
        toast.error("Unable to add a break there.");
        return;
      }

      date = fixedDate ?? date;
    }

    if (isUtcPast(date)) {
      toast.error("Cannot add breaks to the past.");
      return;
    }

    addPlanBreak(date);
  }

  function openCopyDialog(targetSegmentIndex) {
    setTargetSegmentIndex(targetSegmentIndex);
    setShowCopyDialog(true);
  }

  function closeCopyDialog() {
    setShowCopyDialog(false);
  }

  function executeSegmentCopy(sourceSegmentIndex, targetSegmentIndex) {
    if (isUtcPast(segments[targetSegmentIndex].start)) {
      toast.success("Cannot copy into segment that started in the past.");
      return;
    }

    copySegment(
      JSON.parse(
        JSON.stringify(
          segments[sourceSegmentIndex].items.map((item) => ({
            ...item,
            id: null,
            __gstvMeta: { ...item.__gstvMeta, program_id: null },
          })),
        ),
      ),
      segments[targetSegmentIndex].start,
      segments[targetSegmentIndex].items ? segments[targetSegmentIndex].items.length : 0,
    );
    toast.success("Copy Successful");
    closeCopyDialog();
  }

  function addBreak(minutesFromStartOfDay) {
    const start = startOfDay(planDate);
    let date = addMinutes(start, minutesFromStartOfDay);
    // we need to consider that the timezone may change from the start of the day to the point we want to add it
    // for example BST -> GMT
    const timezoneSafetyCheck = getTimezoneDifference(start, date);
    if (timezoneSafetyCheck) {
      // we are on a special day
      date = subMinutes(date, timezoneSafetyCheck);
    }
    addBreakAtPosition(date);
  }

  function segmentIsShort(sIndex) {
    return differenceInMinutes(segments[sIndex].end, segments[sIndex].start) <= SEGMENT_OFFSET_THRESHOLD_MINUTES;
  }

  function getBreakOffset(sIndex) {
    let offset = 0;
    let nextSegIndex = sIndex + 1;

    while (nextSegIndex < segments.length && segmentIsShort(nextSegIndex)) {
      let nextSegDurationMinutes = differenceInMinutes(segments[nextSegIndex].end, segments[nextSegIndex].start);
      const offsetAmount =
        segments[nextSegIndex] && getTimeRemainingInSegment(segments[nextSegIndex], epg)
          ? SEGMENT_OFFSET_PER_MINUTE
          : SEGMENT_OFFSET_PER_MINUTE / 2;

      offset += 5 * offsetAmount + (5 - nextSegDurationMinutes) * offsetAmount;
      nextSegIndex++;
    }

    return `${Math.round(offset)}px`;
  }

  let baseDate = startOfDay(planDate);
  let endDate = endOfDay(planDate);
  endDate.setSeconds(60); // push end to 24:00:00

  if (customRange.length) {
    baseDate = customRange[0];
    endDate = customRange[1];
  }

  return (
    <div className="vertical-scheduler" ref={timelineContainerRef}>
      <CalendarMarkers
        planDate={planDate}
        timezoneOffset={timezoneOffset}
        addBreak={addBreak}
        containerRef={timelineContainerRef}
        secondsPerSection={secondsPerSection}
        pixelsPerSection={pixelsPerSection}
        popoutWidth={markerPopoutWidth}
        changeZoomLevel={changeZoomLevel}
      />
      <CalendarFilterBlocks
        planDate={planDate}
        blocks={blocks ?? []}
        blockWidth={markerPopoutWidth}
        secondsPerSection={secondsPerSection}
        pixelsPerSection={pixelsPerSection}
        applyFilters={applyFilters}
        epg={epg}
      />
      <div className="vertical-scheduler-calendar" ref={calendarDiv}>
        <CurrentTimeMarker
          timeRange={[baseDate, endDate]}
          pixelsPerSecond={pixelsPerSecond}
          date={planDate}
          direction="horizontal"
          considerRange={false}
        />
        {segments.map((seg, sIndex) => (
          <div
            className="vertical-scheduler-calendar__segment"
            key={sIndex}
            style={{
              height: getHeightStyleFromSeconds(
                differenceInSeconds(seg.end, seg.start),
                secondsPerSection,
                pixelsPerSection,
              ),
              ...(sIndex > 0 && getLastProgramForSegment(sIndex - 1, segments)?.till > segments[sIndex - 1].end
                ? {
                    paddingTop: `${getProgramVerticalOffset(segments[sIndex - 1], secondsPerSection, pixelsPerSection)}`,
                  }
                : {}),
            }}
          >
            <Droppable
              droppableId={`${droppableId}-${seg.label}`}
              direction="vertical"
              key={sIndex}
              ignoreContainerClipping={true}
              // because plan date is in the user's timezone we don't need to do a utc comparison
              isDropDisabled={dropsDisabled || endOfDay(planDate) < new Date()}
            >
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={`vertical-scheduler-calendar__plan`}
                >
                  <div className="vertical-scheduler-calendar__bottom" style={{ bottom: getBreakOffset(sIndex) }}>
                    <div
                      className="vertical-scheduler-calendar__pill vertical-scheduler-calendar__pill--secondary"
                      style={{ height: getTimeRemainingInSegmentHis(seg, epg) ? "20px" : 0 }}
                    >
                      <div className="vertical-scheduler-calendar__pill__text">
                        {getTimeRemainingInSegmentHis(seg, epg)}
                      </div>
                    </div>
                    <div className="vertical-scheduler-calendar__pill">
                      <span className="vertical-scheduler-calendar__pill__text">
                        {dateToHourMinutesSeconds(toOffsetDateWithClockChangeSafety(seg.end))}
                      </span>
                      {segments[sIndex + 1] ? (
                        <button
                          className="btn btn--icon vertical-scheduler__btn-copy"
                          onClick={() => openCopyDialog(sIndex)}
                        >
                          <ContentCopyRoundedIcon />
                        </button>
                      ) : null}
                      {segments[sIndex + 1] && segments[sIndex + 1].type === "plan" ? (
                        <button
                          className="btn btn--icon vertical-scheduler__btn-delete"
                          onClick={() => deletePlanBreak(segments[sIndex + 1])}
                        >
                          <CancelRoundedIcon />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  {seg.items.map((program, index) => (
                    <DraggableProgram
                      program={program}
                      segment={seg}
                      onProgramClick={onProgramClick}
                      updateDuration={updateProgramDuration}
                      removeProgram={removeProgram}
                      secondsPerSection={secondsPerSection}
                      pixelsPerSection={pixelsPerSection}
                      index={index}
                      key={index}
                      onProgramDrag={onProgramDrag}
                      smallOffset={getSmallOffset(
                        index,
                        seg.items,
                        SMALL_PROGRAM_MAX_HEIGHT,
                        secondsPerSection,
                        pixelsPerSection,
                      )}
                      smallDuration={SMALL_PROGRAM_MAX_HEIGHT}
                      getHeightFromSeconds={getHeightFromSeconds.bind(null, secondsPerSection, pixelsPerSection)}
                      prependMargin={getPrependedMargin(
                        index,
                        seg.items,
                        SMALL_PROGRAM_MAX_HEIGHT,
                        secondsPerSection,
                        pixelsPerSection,
                      )}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <div className="vertical-scheduler__extra"></div>
          </div>
        ))}
      </div>
      <CopySegmentDialog
        isOpen={showCopyDialog}
        onClose={closeCopyDialog}
        onValidationPass={executeSegmentCopy}
        segments={segments}
        referenceIndex={targetSegmentIndex}
        key={`copy_dialog-${uuid()}`}
      />
    </div>
  );
}

export default VerticalScheduler;
