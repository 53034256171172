import React from "react";
import ReactModal from "react-modal";
import DatePicker from "react-datepicker";
import { createPortal } from "react-dom";
import Loader from "../../common/loader";
import axios from "../../requests/axios";
import { toast } from "react-toastify";
import TemplateScheduleDeleteConfirmationDialog from "./template-schedule-delete-confirmation-dialog";
import { PublishRounded } from "@mui/icons-material";
import format from "date-fns/format";
import { startOfDay } from "date-fns";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";

const DAYS_OF_THE_WEEK = ["S", "M", "T", "W", "Th", "F", "S"];

export default function CalendarEventPopup({ isOpen, onClose, onSuccess, calendarEvent, activeMonth, channelGuid }) {
  const [form, setForm] = React.useState({
    startDate: new Date(calendarEvent?.template_schedule.starts_at),
    endDate: new Date(calendarEvent?.template_schedule.ends_at),
  });
  const [loading, setLoading] = React.useState(false);
  const [showDeleteDialog, setDeleteDialog] = React.useState(false);

  function validateForm() {
    // @TODO add validation
    let valid = true;

    return valid;
  }

  function updateForm(key, value) {
    setForm((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onSaveChanges() {
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      toast.error("validation failed");
      return;
    }

    const payload = {
      month: activeMonth,
      starts_at: form.startDate,
      ends_at: form.endDate,
    };

    axios
      .patch(
        `api/channels/${channelGuid}/template-schedules/${calendarEvent?.template_schedule.template_schedule_id}`,
        payload,
      )
      .then(() => {
        onClose();
        onSuccess();
      })
      .catch((e) => {
        console.error(e);
        toast.error("Error updating this template schedule");
      })
      .finally(() => setLoading(false));
  }

  function onDeleteSchedule() {
    setLoading(true);

    axios
      .delete(`api/channels/${channelGuid}/template-schedules/${calendarEvent?.template_schedule.template_schedule_id}`)
      .then(() => {
        toast.success("Schedule delete successfully");
        onClose();
        onSuccess();
      })
      .catch((e) => {
        console.error(e);
        toast.error("Error attempting to delete this template schedule");
      })
      .finally(() => setLoading(false));
  }

  function onScheduleDay() {
    setLoading(true);
    if (startOfDay(calendarEvent?.start) <= startOfDay(new Date())) {
      toast.error("Changing the schedule of current or past days is not allowed.");
      setLoading(false);
      return;
    }

    axios
      .post(`api/channels/${channelGuid}/plans/${format(calendarEvent?.start, "yyyy-MM-dd")}/schedule`)
      .then(() => {
        toast.success("Schedule applied to plan.");
        window.open(`/scheduler/${channelGuid}/${format(calendarEvent?.start, "yyyy-MM-dd")}`, "_blank");
      })
      .catch((e) => {
        console.error(e);
        toast.error("Error attempting to schedule this day's plan");
      })
      .finally(() => setLoading(false));
  }

  function onUpdatePriority() {
    setLoading(true);

    const payload = {
      template_id: calendarEvent?.template_schedule.template_id,
      template_schedule_id: calendarEvent?.template_schedule.template_schedule_id,
    };

    axios
      .post(`api/channels/${channelGuid}/plans/${format(calendarEvent?.start, "yyyy-MM-dd")}/set-priority`, payload)
      .then(() => {
        toast.success("Event set as priority");
        onSuccess(); // refresh calendar
        onClose();
      })
      .catch((e) => {
        console.error(e);
        toast.error("Error attempting to update priority");
      })
      .finally(() => setLoading(false));
  }

  return (
    <React.Fragment>
      <ReactModal isOpen={isOpen} ariaHideApp={false} shouldCloseOnOverlayClick={true} onRequestClose={onClose}>
        <div className="v-react-modal__inner modal-copy">
          <div className="v-react-modal__heading v-react-modal__heading--flexbox">
            {calendarEvent?.title ?? "N/A"}
            <div className="v-react-modal__heading__actions">
              {calendarEvent?.is_priority ? (
                <button className="btn btn--icon" onClick={() => {}}>
                  <StarRoundedIcon />
                </button>
              ) : (
                <button
                  className="btn btn--icon"
                  onClick={() => onUpdatePriority(true)}
                  aria-label="Set as priority event"
                  data-balloon-pos="left"
                >
                  <StarBorderRoundedIcon />
                </button>
              )}
              {loading ? (
                <button className="btn btn--icon" aria-label="scheduling..." data-balloon-pos="left">
                  <Loader />
                </button>
              ) : (
                <button
                  className="btn btn--icon"
                  onClick={onScheduleDay}
                  aria-label="Schedule day"
                  data-balloon-pos="left"
                >
                  <PublishRounded />
                </button>
              )}
            </div>
          </div>
          <div className="v-react-modal__body modal-copy__body">
            <div className="modal-copy__dropdown-group">
              <div className="modal-copy__body__text">Template:</div>
              <div className="modal-copy__dropdown">
                {calendarEvent?.template_schedule.template.template_name ?? "N/A"}
              </div>
            </div>

            <div className="modal-copy__dropdown-group">
              <div className="modal-copy__body__text">Frequency:</div>
              <div className="modal-copy__dropdown">{calendarEvent?.template_schedule.frequency}</div>
            </div>

            {calendarEvent && calendarEvent.template_schedule.frequency === "selection" ? (
              <div className="modal-copy__dropdown-group">
                <div className="modal-copy__body__text"></div>
                <div className="days-of-week-selector">
                  {DAYS_OF_THE_WEEK.map((day, index) => (
                    <div
                      className={`days-of-week-selector__segment days-of-week-selector__segment--fixed ${calendarEvent?.template_schedule.days_of_week && calendarEvent?.template_schedule.days_of_week.includes(index) ? "days-of-week-selector__segment--selected" : ""}`}
                      key={`day-${index}`}
                    >
                      {day}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}

            <div className="modal-copy__dropdown-group">
              <div className="modal-copy__body__text">Start date:</div>
              <div className="modal-copy__dropdown">
                <DatePicker
                  selected={form.startDate}
                  onChange={(date) => updateForm("startDate", new Date(date))}
                  minDate={new Date()}
                  popperContainer={({ children }) => createPortal(children, document.body)}
                  dateFormat="yyyy-MM-dd"
                />
              </div>
            </div>

            <div className="modal-copy__dropdown-group">
              <div className="modal-copy__body__text">End date:</div>
              <div className="modal-copy__dropdown">
                <DatePicker
                  selected={form.endDate}
                  onChange={(date) => updateForm("endDate", new Date(date))}
                  minDate={new Date(form.startDate)}
                  popperContainer={({ children }) => createPortal(children, document.body)}
                  dateFormat="yyyy-MM-dd"
                />
              </div>
            </div>
          </div>
          <div className="v-react-modal__footer">
            {loading ? (
              <React.Fragment>
                <Loader width={45} height={45} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <button className="btn btn--inverse-primary" onClick={onClose}>
                  Close
                </button>
                {calendarEvent && startOfDay(calendarEvent?.start) <= startOfDay(new Date()) ? null : (
                  <React.Fragment>
                    <button className="btn btn--danger-inverse" onClick={() => setDeleteDialog(true)}>
                      Delete Schedule
                    </button>
                    <button className="btn btn--primary" onClick={onSaveChanges}>
                      Save Changes
                    </button>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </ReactModal>
      <TemplateScheduleDeleteConfirmationDialog
        isOpen={showDeleteDialog}
        onClose={() => setDeleteDialog(false)}
        onConfirm={onDeleteSchedule}
      />
    </React.Fragment>
  );
}
