import React from "react";
import Select from "../../../common/select.jsx";
import { getSeasonsForSeries } from "../../../requests/api-requests.js";

const defaultOption = {
  label: "All",
  value: "",
};

function SeasonFilter({ seriesId, season, onChange, disabled = false }) {
  const [isLoading, setLoading] = React.useState(false);
  const [seasons, setSeasons] = React.useState([]);

  React.useEffect(() => {
    setSeasons([]); // reset list

    if (seriesId) {
      setLoading(true);

      getSeasonsForSeries(seriesId)
        .then((data) => {
          const nextSeasons = data.map((s) => ({
            label: `Season ${s.season_number}`,
            value: s.season_guid,
          }));

          if (nextSeasons.length) {
            nextSeasons.unshift(defaultOption);
          }

          setSeasons(nextSeasons);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => setLoading(false));
    }
  }, [seriesId]);

  React.useEffect(() => {
    if (seriesId && season?.value && !season.label) {
      let label = seasons.find((s) => s.value === season.value)?.label;
      if (label) {
        const fixed = {
          label,
          value: season.value,
        };
        onChange(fixed);
      }
    }
  }, [seasons, season, seriesId, onChange]);

  const selected = !seasons.length ? null : season.value ? season : defaultOption;

  return (
    <Select
      name={"season"}
      value={selected}
      options={seasons}
      placeholder={seriesId ? "No Seasons" : "N/A"}
      onChange={(season) => onChange(season)}
      isSearchable={false}
      isDisabled={disabled || !seriesId}
      isLoading={isLoading}
      containerClass="react-select-container--light react-select-container--small"
    />
  );
}

export default SeasonFilter;
